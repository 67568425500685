import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Subject } from "rxjs";
import { AppSettingsService } from './data-services/app-settings.service';
import { IIntegrationData } from './interfaces/IntegrationData';
import { ISignalRMessage, ISignalRNotification } from './interfaces/SignalR';

@Injectable({
  providedIn: 'root',
})
export class AppSignalrService {
  private static connection: HubConnection;

  public isConnected = false;
  private signalRSource = new Subject<ISignalRNotification>();
  signalRChange = this.signalRSource.asObservable();

  private signalRSource2 = new Subject<ISignalRMessage>();
  signalRChange2 = this.signalRSource2.asObservable();

  private signalRSource3 = new Subject<IIntegrationData>();
  signalRChange3 = this.signalRSource3.asObservable();

  public reconectionInProgress = false;

  constructor() {
    try {
      // this.InitSignalR();

      // interval(10000).subscribe(e => {
      //   if (!this.isConnected) {
      //     console.log('autoconnect');
      //     this.InitSignalR();
      //   }
      // });

    } catch (e) {
      this.isConnected = false;
      console.log('SignalR Init connection Error');
    }
  }

  public CloseSignalR() {
    if (AppSignalrService !== undefined  && AppSignalrService.connection !== undefined) {
      AppSignalrService.connection.stop().then(a => {
        this.isConnected = false;
        console.log('SignalR connection closed');
        this.reconectionInProgress = false;
      });
    }
  }

  InitSignalR(authToken: string) {
    try {
      if (this.isConnected) {
        AppSignalrService.connection.stop().then(a => {
          this.isConnected = false;
        });
      }

      AppSignalrService.connection = new HubConnectionBuilder()
        .withUrl(`${AppSettingsService.Settings.baseUrlToken}/mmlrtcomms`, { accessTokenFactory: () => authToken })
        .configureLogging(LogLevel.Information)
        .build();

      AppSignalrService.connection.start().then(a => {
        this.isConnected = true;
        console.log('SignalR Service Connected');
        this.reconectionInProgress = false;
        // AppSignalrService.connection.invoke('RegisterUser', 'juancarlos@mymedleads.com').catch( err => console.log(err));
      });

      AppSignalrService.connection.onclose(err => {
        this.isConnected = false;
        console.log('SignalR Service Disconnected');
      });

      AppSignalrService.connection.on('OnTestSignal', (message) => {
        console.log('TEST SIGNAL RECEIVED FROM SERVER - SERVICE');
        console.log(message);
      });

      // Notifications
      AppSignalrService.connection.on('OnSendNotificationToAccount', (notification: ISignalRNotification) => {
        console.log('OnSendNotificationToAccount - SERVICE');
        console.log(notification);
        this.signalRSource.next(notification);
      });

      AppSignalrService.connection.on('OnSendNotificationToAccountMember', (notification: ISignalRNotification) => {
        console.log('OnSendNotificationToAccountMember - SERVICE');
        console.log(notification);
        this.signalRSource.next(notification);
      });

      AppSignalrService.connection.on('OnSendNotificationToAll', (notification: ISignalRNotification) => {
        console.log('OnSendNotificationToAll - SERVICE');
        console.log(notification);
        this.signalRSource.next(notification);
      });

      AppSignalrService.connection.on('OnSendSmsToAccount', (notification) => {
        console.log('OnSendSmsToAccount - SERVICE');
        console.log(notification);
        this.signalRSource.next(notification);
      });

      // Messages
      AppSignalrService.connection.on('OnSendMessageToAccountMember', (message: ISignalRMessage) => {
        console.log('OnSendMessageToAccountMember - SERVICE');
        this.signalRSource2.next(message);
      });

      AppSignalrService.connection.on('OnSendMessageToAccount', (message: ISignalRMessage) => {
        console.log('OnSendMessageToAccount - SERVICE');
        this.signalRSource2.next(message);
      });

      // Integration
      AppSignalrService.connection.on('OnSendIntegrationDataToAdmin', (data: IIntegrationData) => {
        console.log('OnSendIntegrationDataToAdmin - SERVICE');
        this.signalRSource3.next(data);
      });

      // AppSignalrService.connection.on('OnSendMessageToAccount', (message: ISignalRMessage) => {
      //   console.log('OnSendMessageToAccount - SERVICE');
      //   console.log(message);
      //   this.signalRSource.next(message);
      // });

      // AppSignalrService.connection.on('OnSendSmsToAccount', (message) => {
      //   console.log('OnSendSmsToAccount - SERVICE');
      //   console.log(message);
      //   this.signalRSource.next(message);
      // });

      //  AppSignalrService.connection.on('ReceiveMessage', (message, AccountId) => {
      //   console.log('SignalR Service ReceiveMessage');
      // });

      // this.connection.on('NewMessage', (message, AccountId, value1) => {
      //   console.log('SignalR Service NewMessage');
      //   this.signalRSource.next(this.CreateMsg(SignalRMessageEnum.NewMessage, message, AccountId, value1));
      // });

      // this.connection.on('QueuedMessagesUpdated', (message, AccountId, value1) => {
      //   console.log('SignalR Service QueuedMessagesUpdated');
      //   this.signalRSource.next(this.CreateMsg(SignalRMessageEnum.QueuedMessagesUpdated, message, AccountId, value1));
      // });

      // this.connection.on('NewEmail', (message, AccountMemberId, value1) => {
      //   console.log('SignalR Service NewEmail');
      //   this.signalRSource.next(this.CreateMsg(SignalRMessageEnum.NewEmail, message, 0, AccountMemberId, value1));
      // });

      // this.connection.on('ConsentStatusUpdated', (message, AccountId, LeadPhoneId) => {
      //   console.log('SignalR Service ConsentStatusUpdated');
      //   this.signalRSource.next(this.CreateMsg(SignalRMessageEnum.ConsentStatusUpdated, message, AccountId, LeadPhoneId));
      // });

      // this.connection.on('SendMessageToAccountMember', (message) => {
      //   console.log('SignalR Service SendMessageToAccountMember');
      //   console.log('!!!!!!!!!!!!!');
      //   console.log(message);
      // });

    } catch (e) {
      this.isConnected = false;
      console.log('SignalR connection Error');
    }
  }

}
