
// export interface IMessageBox {
// 	Id: number;
// 	LeadId: number;
// 	Type: MessageBoxTypeEnum;
// 	Direction:  MessageDirectionEnum;
// 	PostDate: string;
// 	AlertDate: string;
// 	Content: string;
// 	WithSmsReference: boolean;
// 	SmsId?: number;
// 	WithEMailReference: boolean;
// 	EMailId?: number;
//     UserName: string;
//     ImgName: string;
//     ImgTitle: string;
// 	BadgeRed?: string;
// 	BadgeGreen?: string;
// 	BadgeOrange?: string;
// }

export interface IMessageBox {
  Id: number;
  LeadId: number;
  AccountMemberId: number;
  Type: MessageBoxTypeEnum;
  Direction: MessageDirectionEnum;
  PostDate: string;
  AlertDate: string;
  Content: string;
  UserName: string;
  UserImg: string;
  ImgName: string;
  ImgTitle: string;

  ReminderId?: number;
  AppointmentId?: number;
  AppEmailId?: number;
  LeadIncomingEMailId?: number;
  CallId?: number;
  HasRecording: boolean;

  BadgeRed?: string;
  BadgeGreen?: string;
  BadgeOrange?: string;
  BadgeBlue?: string;
  DisplayStatus: MessageBoxDisplayStatusEnum;
}

export interface INote {
  PostDate: string;
  Content: string;
  Id: number;
  DateCreated?: any;
  DateUpdated?: any;
  CreatedBy?: any;
  UpdatedBy?: any;
}

export enum MessageBoxTypeEnum {
  System = 0,
  LeadPosted,
  CallTrackingLeadPosted,
  Sms,
  EMail,
  Note,
  Appointment,
  Call,
  Task,
  Reminder
}

export enum MessageDirectionEnum {
  System = 0,
  Misc = 1,
  Incoming = 2,
  Outgoing = 3,
}

export enum MessageBoxDisplayStatusEnum {
  Undefined= 0,
  LeadPosted,
  UserAssigned,
  NoteActive,
  NoteDeteled,
  ReminderActive,
  ReminderCompleted,
  ReminderCanceled
}
